import React, { FunctionComponent, useContext } from 'react';
import { useAppSelector } from 'hooks/redux';
import { EventModel } from '../../EventModel';
import { liveEventSelector } from '../../eventsSelectors';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { Icon } from 'components/Icons';
import { useCurrentEvent, useIsEventLive } from '../../eventHooks';
import { currentBroadcastFinishedSelector } from 'features/broadcast/selectors';
import { ThumbImage } from 'components/Images/ThumbImage';
import { UserAgentContext } from 'features/user-agent/userAgent';

export const LiveBanner: FunctionComponent = () => {
  const liveEvent: EventModel | null = useAppSelector(liveEventSelector);
  const event = useCurrentEvent();
  const currentBroadcastFinished = useAppSelector(
    currentBroadcastFinishedSelector
  );
  const { pathname } = useLocation();
  const isEventPath = matchPath('/events/:id', pathname);
  const isLive = useIsEventLive(event);
  const { isCreatorAppWebview } = useContext(UserAgentContext);

  if (
    !liveEvent ||
    event?.endedAt ||
    currentBroadcastFinished ||
    !isEventPath ||
    isLive ||
    isCreatorAppWebview
  ) {
    return null;
  }

  return (
    <Link
      to={`/events/${liveEvent.id}`}
      state={{ replace: true, animate: false, autoPlay: true }}
      className="tw-max-w-[95%] sm:tw-max-w-lg tw-w-full tw-p-4 tw-bg-black tw-bg-opacity-80 tw-flex tw-gap-4 tw-font-bold tw-text-base tw-text-white hover:tw-text-white tw-rounded-2xl tw-top-[68px] md:tw-top-4 tw-fixed tw-left-1/2 tw--translate-x-1/2 tw-z-[1000] [.ios-listener_&]:tw-top-[calc(80px+var(--sat))] [.android-listener_&]:tw-top-[calc(80px+var(--sat))]"
    >
      <div className="tw-rounded-md tw-overflow-hidden tw-h-20 tw-w-20 tw-relative [&>img]:tw-h-full [&>img]:tw-w-full [&>img]:tw-object-cover">
        <ThumbImage
          artworkUrl={liveEvent.artwork.image.small}
          artworkMode={liveEvent.artworkMode}
        />
      </div>
      <div className="tw-flex-1">
        <div className="tw-flex tw-gap-2 tw-text-mixlr-red tw-uppercase tw-items-center">
          <Icon
            icon={'liveNowAnimated'}
            height={12}
            hidden={true}
            label={'One Air'}
            width={12}
          />
          <span>Live now</span>
        </div>
        <p className="tw-text-xl tw-font-medium tw-line-clamp-2 tw-m-0">
          {liveEvent.title}
        </p>
      </div>
    </Link>
  );
};
