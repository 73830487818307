import React, { FunctionComponent, useContext, useState } from 'react';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { isMobile, isAndroid } from 'react-device-detect';
import { ModalWrapper } from 'components/Modal/Wrapper';
import { IconButton } from 'components/Buttons/IconButton';
import { useLocalStorage } from 'usehooks-ts';
import { ReactComponent as AppStore } from 'images/app-store.svg';
import { ReactComponent as GooglePlayStore } from 'images/google-play-badge.svg';
import { ReactComponent as MixlrLogo } from 'images/mixlr-logo-single.svg';

export interface DeepLinkBannerProps {
  deepLink: string;
}

export const DeepLinkBanner: FunctionComponent<DeepLinkBannerProps> = ({
  deepLink
}) => {
  const [showBanner, setShowBanner] = useState(true);
  const [showDeepLinkModal, setShowDeepLinkModal] = useState(false);
  const { isNativeAppWebview } = useContext(UserAgentContext);
  const [deepLinkBannerVisible, setDeepLinkBannerVisible] = useLocalStorage(
    'deeplinkbanner-visible',
    true
  );

  const openModal = () => {
    setShowDeepLinkModal(true);
  };

  const closeModal = () => {
    setShowDeepLinkModal(false);
  };

  const hideBanner = () => {
    setShowBanner(false);
  };

  const removeBanner = () => {
    setDeepLinkBannerVisible(false);
    setShowDeepLinkModal(false);
  };

  const openApp = () => {
    setShowDeepLinkModal(false);
  };

  const appStoreLink = (): string => {
    let link = '';
    if (isAndroid) {
      link = 'https://play.google.com/store/apps/details?id=com.mixlr.android';
    } else {
      link = 'https://itunes.apple.com/app/mixlr/id583705714';
    }
    return link;
  };

  if (
    isNativeAppWebview ||
    !isMobile ||
    !showBanner ||
    !deepLinkBannerVisible
  ) {
    return null;
  }

  return (
    <>
      <div className="tw-flex tw-items-center tw-justify-center tw-bg-black tw-text-white tw-text-center tw-py-2 tw-px-4 tw-relative tw-z-50">
        <button
          onClick={openModal}
          className="button--reset tw-flex tw-items-center tw-justify-center tw-bg-mixlr-red tw-h-[36px] tw-w-[36px] tw-drop-shadow-xl tw-rounded-xl"
        >
          <MixlrLogo className="tw-h-[32px] tw-w-[32px]" />
        </button>
        <button
          onClick={openModal}
          className="button--reset tw-text-white tw-flex-1 tw-text-center"
        >
          Open in Mixlr App
        </button>
        <IconButton
          label={`Hide banner`}
          onClick={hideBanner}
          icon={'x'}
          showLabel={false}
          size={'small'}
          noBackground={true}
        />
      </div>
      <ModalWrapper
        isOpen={showDeepLinkModal}
        title={'Mixlr for Listeners'}
        onRequestClose={closeModal}
      >
        <div className="tw-text-center">
          <p>Open this on the Mixlr App</p>
          <a
            href={deepLink}
            className="tw-block tw-mb-8 tw-p-4 tw-font-bold tw-bg-theme-color tw-text-black [.dark-contrast_&]:tw-text-white tw-text-center tw-rounded-xl"
            onClick={openApp}
            target="_blank"
            rel="noreferrer"
          >
            Launch Mixlr App
          </a>
          <p>Or...</p>
          <a
            href={appStoreLink()}
            className="tw-block tw-mb-8 tw-text-center"
            target="_blank"
            rel="noreferrer"
          >
            {isAndroid ? <GooglePlayStore /> : <AppStore />}
          </a>
          <button
            onClick={removeBanner}
            className="button--reset tw-mx-auto tw-w-full tw-text-center"
          >
            Don&apos;t show this again
          </button>
        </div>
      </ModalWrapper>
    </>
  );
};
