import { UserAgentContext } from 'features/user-agent/userAgent';
import React, { FunctionComponent, useContext } from 'react';

interface FullScreenTitleProps {
  title: string;
}

export const FullScreenTitle: FunctionComponent<FullScreenTitleProps> = ({
  title
}) => {
  const { isNativeAppWebview } = useContext(UserAgentContext);
  return (
    <h1
      className={`tw-mb-2 md:tw-mb-4 [.embed_&]:tw-mb-0 [.ios_&]:tw-mb-1 ${
        isNativeAppWebview
          ? 'tw-text-3xl [.ios_&]:tw-text-xl'
          : 'tw-text-3xl md:tw-text-4xl [.embed_&]:tw-text-2xl'
      } tw-line-clamp-3 [.embed_&]:tw-line-clamp-1`}
    >
      {title}
    </h1>
  );
};
