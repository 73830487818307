import React, { FunctionComponent } from 'react';
import { Pill } from 'components/Pill';
import { useAppSelector } from 'hooks/redux';
import { LiveNow } from 'components/LiveNow';

interface CardStatusesProps {
  isLive?: boolean;
  isPrivate?: boolean;
  duration?: string;
  type: 'event' | 'recording';
}

export const CardStatuses: FunctionComponent<CardStatusesProps> = ({
  isLive,
  isPrivate,
  duration,
  type
}) => {
  const playing = useAppSelector((state) => state.audio.playing);

  return (
    <div className="tw-inline-flex tw-gap-4">
      {type === 'event' && isLive && <LiveNow isLive={isLive} />}
      {type === 'event' && !isLive && (
        <Pill label={'Upcoming'} type={'live'} icon={'calendar'} />
      )}
      {type === 'recording' && (
        <Pill label={duration} type={'live'} icon={playing ? 'stop' : 'play'} />
      )}
      {isPrivate && <Pill label={'Private'} type={'default'} icon={'lock'} />}
    </div>
  );
};
