import React, { FunctionComponent, useContext } from 'react';
import { FollowButtons } from 'features/chat/components/FollowButtons';
import { SupportButton } from 'components/Support/SupportButton';
import { ChannelSocialLinks } from 'features/channel/components/ChannelSocialLinks';
import { useCurrentUser } from 'features/current-user/hooks';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { IconButton } from 'components/Buttons/IconButton';
import { useDispatch } from 'react-redux';
import { toggleSearchVisibility } from 'features/search/searchSlice';
import { useAppSelector } from 'hooks/redux';

export const ChannelInfo: FunctionComponent = () => {
  const channel = useAppSelector((state) => state.channel.channelDetails);
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  const { isNativeAppWebview, isCreatorAppWebview } =
    useContext(UserAgentContext);

  const showSearch = () => {
    dispatch(toggleSearchVisibility(true));
  };

  if (!channel || isCreatorAppWebview) {
    return null;
  }

  return (
    <div className="tw-flex tw-items-center tw-px-4 tw-py-2 md:tw-px-8 md:tw-py-4 tw-border-line-color tw-border-solid tw-border-0 tw-border-t-[1px] tw-border-b-[1px] [.app-listener_&]:tw-border-t-0">
      {!isNativeAppWebview && (
        <h1 className="tw-truncate tw-mb-0 tw-text-3xl tw-text-bold tw-mr-auto">
          {channel.username}
        </h1>
      )}
      <div className="tw-flex tw-items-center tw-gap-8 [.ios_&]:tw-gap-4 tw-list-none">
        <SupportButton showAslink />
        <FollowButtons
          showAslink
          selectedProfileId={channel.ownerId}
          followedIds={(currentUser && currentUser.followedUserIds) || []}
        />
        <ChannelSocialLinks iconList={true} />
      </div>
      {isNativeAppWebview && (
        <div className="tw-ml-auto tw-relative -tw-right-[10px]">
          <IconButton
            label={'Show search'}
            onClick={showSearch}
            size={'small'}
            icon={'search'}
            noBackground={true}
          />
        </div>
      )}
    </div>
  );
};
