import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useCallback
} from 'react';
import { Helmet } from 'react-helmet';
import {
  isMobileOnly,
  isTablet,
  isDesktop,
  browserName,
  browserVersion
} from 'react-device-detect';
import { useLocation, matchPath } from 'react-router-dom';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { ThemeContext } from 'features/theme/theme';
import { cloudflareUrl } from 'utilities/cloudflareImageResizer';
import fallbackImage from 'images/mixlr-logo-single.svg';
import { useAppSelector } from 'hooks/redux';
import classNames from 'classnames';
import reactManifest from 'react-manifest';

export const HelmetTags: FunctionComponent = () => {
  const { deviceClassName } = useContext(UserAgentContext);
  const { theme, themeContrast, themeColor, darkMode } =
    useContext(ThemeContext);
  const { pathname } = useLocation();
  const isEventPath = matchPath('/events/:id', pathname);
  const isRecordingPath = matchPath('/recordings/:id', pathname);
  const channelLogoKey = useAppSelector(
    (state) => state.channel.channelDetails?.logoKey
  );
  const channelName = useAppSelector(
    (state) => state.channel.channelDetails?.username
  );

  const generateFaviconUrl = useCallback(
    (size: number): string | undefined => {
      return (
        cloudflareUrl(channelLogoKey, {
          height: size,
          width: size,
          quality: 100,
          baseUrl: process.env.REACT_APP_STORAGE_CDN_URL,
          format: 'png',
          fit: 'cover'
        }) || fallbackImage
      );
    },
    [channelLogoKey]
  );

  useEffect(() => {
    const manifestDetails = {
      name: channelName,
      short_name: channelName,
      start_url: 'index.html',
      display: 'standalone',
      orientation: 'portrait',
      theme_color: themeColor,
      background_color: '#ffffff',
      icons: [
        {
          src: generateFaviconUrl(192),
          sizes: '192x192'
        },
        {
          src: generateFaviconUrl(512),
          sizes: '512x512'
        }
      ]
    };
    reactManifest.update(manifestDetails, '#manifest-placeholder');
  }, [themeColor, channelName, generateFaviconUrl]);

  return (
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400..700;1,400..700&display=swap"
        rel="stylesheet"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={generateFaviconUrl(32)}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={generateFaviconUrl(16)}
      />
      <link
        rel="apple-touch-icon"
        type="image/png"
        sizes="180x180"
        href={generateFaviconUrl(180)}
      />
      <meta name="msapplication-TileColor" content={themeColor} />
      <meta name="theme-color" content={themeColor} />
      <html
        className={classNames(deviceClassName, { 'dark-mode': darkMode })}
      />
      <body
        className={classNames(
          theme,
          themeContrast,
          `${browserName.toLowerCase()}-${browserVersion}`,
          {
            mobile: isMobileOnly,
            tablet: isTablet,
            desktop: isDesktop,
            'body-full-screen': isEventPath || isRecordingPath,
            'body-event': isEventPath,
            'body-recording': isRecordingPath,
            'dark-mode': darkMode
          }
        )}
      />
    </Helmet>
  );
};
