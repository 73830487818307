import React, { FunctionComponent, useEffect, useContext } from 'react';
import { FullScreenContent } from 'components/Layouts/FullScreenView/FullScreenContent';
import { EventModel } from 'features/events/EventModel';
import { EventCountdown } from 'features/events/EventCountdown';
import { useIsEventLive } from 'features/events/eventHooks';
import { EventPlayer } from 'features/events/components/EventPlayer';
import { EventChat } from './EventChat';
import { useAppSelector } from 'hooks/redux';
import { currentBroadcastFinishedSelector } from 'features/broadcast/selectors';
import { useNativeEvents } from 'hooks/useNativeEvents';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { EventMeta } from './components/EventMeta';
import { ListenerCount } from 'components/ListenerCount';
import { FullScreenDetails } from 'components/Layouts/FullScreenView/FullScreenDetails';
import { EventInfo } from './EventInfo';
import { FullScreenControls } from 'components/Layouts/FullScreenView/FullScreenControls';
import { HeartButton } from 'components/Hearts/HeartButton';
import { Share } from 'components/Share';
import { SquareImage } from 'components/Images/SquareImage';
import { useMediaQuery } from 'react-responsive';
import { EventChatWithPlayer } from './EventChatWithPlayer';
import { ChatButton } from 'features/chat/components';
import useForceUpdate from 'hooks/useForceUpdate';
import { useDispatch } from 'react-redux';
import { setNativeEventStarted } from './eventsSlice';

interface EventWrapperProps {
  event: EventModel | null;
}

export const EventContent: FunctionComponent<EventWrapperProps> = ({
  event
}) => {
  const isLive = useIsEventLive(event);
  const { postMessage } = useNativeEvents();
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const { isListenerAppWebview, isCreatorAppWebview } =
    useContext(UserAgentContext);

  const currentBroadcastFinished = useAppSelector(
    currentBroadcastFinishedSelector
  );

  const renderChatView = () => {
    if (isListenerAppWebview) {
      return <EventChat event={event} />;
    } else if (isMobile) {
      return <EventChatWithPlayer event={event} />;
    }
    return <EventChat event={event} />;
  };

  const nativeEventStarted = useAppSelector(
    (state) => state.events.nativeEventStarted
  );

  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();

  useEffect(
    function forceUpdateIfCreatorAppLive() {
      // Force a state update if the nativeEventStarted has
      // been sent but we are not showing as live
      if (isCreatorAppWebview && nativeEventStarted && !isLive) {
        const isLiveTimeout = setTimeout(() => {
          forceUpdate();
          dispatch(setNativeEventStarted(false));
        }, 8000);
        return () => clearTimeout(isLiveTimeout);
      }
    },
    [isCreatorAppWebview, isLive, nativeEventStarted, forceUpdate, dispatch]
  );

  useEffect(() => {
    if (isListenerAppWebview) {
      postMessage({
        eventLive: isLive
      });
    }
  }, [isLive, isListenerAppWebview, postMessage]);

  if (!event) {
    return null;
  }

  if (isLive) {
    return (
      <FullScreenContent
        centerColumn={
          <FullScreenDetails
            title={event.title}
            image={
              isListenerAppWebview ? null : (
                <SquareImage
                  artworkUrl={event.artwork.image.large}
                  cssClasses={'tw-shadow-glow'}
                />
              )
            }
            meta={
              <>
                <EventMeta />
                <ListenerCount />
              </>
            }
            controls={
              <FullScreenControls>
                <HeartButton />
                <ChatButton />
                <Share title={event.title} label={'event'} />
                <EventInfo event={event} />
              </FullScreenControls>
            }
          />
        }
        player={<EventPlayer />}
        rightColumn={renderChatView()}
        renderAtBottom={isListenerAppWebview}
      />
    );
  } else {
    const rightColumn = currentBroadcastFinished ? renderChatView() : null;

    return (
      <FullScreenContent
        centerColumn={
          <FullScreenDetails
            image={
              isListenerAppWebview && currentBroadcastFinished ? null : (
                <SquareImage
                  artworkUrl={event.artwork.image.large}
                  cssClasses={'tw-shadow-glow'}
                />
              )
            }
            meta={<EventCountdown />}
            controls={
              <FullScreenControls>
                {currentBroadcastFinished && <ChatButton />}
                <Share title={event.title} label={'event'} />
                <EventInfo event={event} />
              </FullScreenControls>
            }
          />
        }
        rightColumn={rightColumn}
        renderAtBottom={isListenerAppWebview && currentBroadcastFinished}
      />
    );
  }
};
