import { Loader } from 'components/Loader';
import React, { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { FullImage } from 'components/Images/FullImage';
import parse from 'html-react-parser';
import classNames from 'classnames';
import { useAppSelector } from 'hooks/redux';

export const ChannelDetails: FunctionComponent = () => {
  const channel = useAppSelector((state) => state.channel.channelDetails);
  const channelisLoaded = useAppSelector((state) => state.channel.isLoaded);
  const artworkUrl = useAppSelector(
    (state) => state.channel.channelDetails?.channelArtwork.image.large || null
  );

  if (!channelisLoaded || channel == null) {
    return <Loader fillComponent={true} />;
  }

  return (
    <div className={classNames(styles['channel-details'])}>
      <FullImage
        artworkUrl={artworkUrl}
        artworkMode={'theme'}
        type={'event'}
        hideOverlay={true}
      />
      <div className={styles['channel-details__content']}>
        <h1 className={styles['channel-details__title']}>
          {parse(`<span>Welcome to</span> ${channel.username}`)}
        </h1>
      </div>
    </div>
  );
};
