import { useAppSelector } from 'hooks/redux';
import React, { FunctionComponent, ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import { matchPath, useLocation } from 'react-router-dom';
import { FullScreenTitle } from './FullScreenTitle';

interface FullScreenDetailsProps {
  title?: string | null;
  image?: ReactNode;
  meta?: ReactNode;
  controls?: ReactNode;
}

export const FullScreenDetails: FunctionComponent<FullScreenDetailsProps> = (
  props
) => {
  const chatVisible = useAppSelector((state) => state.chat.isVisible);
  const isMobile = useMediaQuery({
    query: '(max-width: 640px) and (max-height: 660px)'
  });
  const { pathname } = useLocation();
  const isEventPath = matchPath('/events/:id', pathname);
  const chatVisibleOnSmallScreens = isMobile && chatVisible && isEventPath;

  return (
    <div
      className={`tw-w-full sm:tw-text-center tw-space-y-4 md:tw-space-y-8 [.ios_&]:tw-space-y-4 tw-text-white [.light-contrast_&]:tw-text-black [.has-image_&]:!tw-text-white tw-px-8 [.embed_&]:tw-text-left [.embed_&]:tw-px-2 ${
        chatVisibleOnSmallScreens
          ? 'tw-flex tw-gap-8 tw-items-center mobile-landscape:tw-block'
          : ''
      }`}
    >
      {props.image && (
        <div
          className={`${
            chatVisibleOnSmallScreens ? 'tw-max-w-[100px]' : 'tw-max-w-[180px]'
          } sm:tw-max-w-[164px] md:tw-max-w-[248px] lg:tw-max-w-[356px] tw-w-full tw-mx-auto mobile-landscape:tw-max-w-[80px] [.ios_&]:mobile-portrait:tw-max-w-full tw-flex-none`}
        >
          {props.image}
        </div>
      )}
      <div className="tw-flex-1">
        {props.title && <FullScreenTitle title={props.title} />}
        {props.meta && (
          <div className="tw-flex tw-space-x-8 tw-justify-center tw-items-center tw-mb-2 md:tw-mb-4 [.embed_&]:tw-justify-start [.embed_&]:tw-mb-0 [.embed_&]:tw-text-xl">
            {props.meta}
          </div>
        )}
        {props.controls && props.controls}
      </div>
    </div>
  );
};
