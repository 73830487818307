import { sendBroadcastHeart } from 'features/broadcast/thunks';
import { useIsBroadcaster, useCurrentUser } from 'features/current-user/hooks';
import React, { FunctionComponent, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { HeartCount } from 'components/Hearts/HeartCount';
import { useCurrentEvent } from 'features/events/eventHooks';
import { showAuthenticationEmailPromptForm } from 'features/current-user/currentUserSlice';
import { useAppSelector } from 'hooks/redux';
import { currentEventBroadcastSelector } from 'features/broadcast/selectors';
import { IconButton } from 'components/Buttons/IconButton';
import { Icon } from '../Icons';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useMediaQuery } from 'react-responsive';

interface HeartButtonProps {
  hideCount?: boolean;
}

export const HeartButton: FunctionComponent<HeartButtonProps> = (props) => {
  const currentBroadcast = useAppSelector(currentEventBroadcastSelector);
  const currentBroadcastUID = currentBroadcast?.uid;

  const isHeartLoading = useSelector(
    (state: RootState) => state.broadcasts.heartLoading
  );
  const currentUser = useCurrentUser();
  const isBroadcaster = useIsBroadcaster();
  const dispatch = useDispatch();
  const event = useCurrentEvent();
  const { isListenerAppWebview, isEmbed } = useContext(UserAgentContext);

  const heartingButtonDisabled = !currentBroadcastUID || isBroadcaster;
  const removeEmbedBranding = useAppSelector(
    (state) => state.channel?.channelDetails?.removeEmbedBranding
  );
  const hideHeartButtonOnEmbedScreenWidth = useMediaQuery({
    query: '(max-width: 400px)'
  });
  const hideHeartButtonOnEmbed =
    isEmbed && (removeEmbedBranding || hideHeartButtonOnEmbedScreenWidth);

  const heartBroadcast = () => {
    if (!currentBroadcastUID) {
      return;
    }
    if (isEmbed) {
      window.open(window.location.href, '_blank');
      return;
    }
    if (currentUser) {
      dispatch(sendBroadcastHeart(currentBroadcastUID));
    } else {
      if (isListenerAppWebview) {
        window.location.href = `mixlr://auth?url=${process.env.REACT_APP_WEBSITE_ENDPOINT}/signin`;
      } else {
        dispatch(showAuthenticationEmailPromptForm(`To send a ❤️`));
      }
    }
  };

  if (hideHeartButtonOnEmbed) {
    return null;
  }

  if (event && !event.heartingEnabled) {
    return null;
  }

  const renderIconOrButton = () => {
    if (heartingButtonDisabled) {
      return (
        <Icon hidden={true} icon={'heart'} label={''} height={20} width={20} />
      );
    }
    return (
      <IconButton
        label={'Heart this event'}
        onClick={heartBroadcast}
        icon={'heart'}
        isDisabled={isHeartLoading}
        isLoading={isHeartLoading}
        loadingType={'fill'}
      />
    );
  };

  if (props.hideCount) {
    if (heartingButtonDisabled) {
      return null;
    }
    return (
      <IconButton
        label={'Heart this event'}
        onClick={heartBroadcast}
        icon={'heart'}
        isDisabled={isHeartLoading}
        isLoading={isHeartLoading}
        loadingType={'fill'}
        isLight={true}
      />
    );
  }

  return (
    <div
      className={`tw-flex tw-items-center tw-rounded-full tw-h-[36px] tw-space-x-1 tw-bg-black/30 tw-text-white tw-backdrop-blur-sm ${
        heartingButtonDisabled ? 'tw-pl-4' : ''
      }`}
    >
      {renderIconOrButton()}
      <HeartCount onlyCount={true} />
    </div>
  );
};
