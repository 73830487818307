import React, { FunctionComponent, ChangeEvent, useContext } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { Icon } from '../Icons';
import { UserAgentContext } from 'features/user-agent/userAgent';
import { useAudio } from 'features/audio/audio';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'rootReducer';
import { setCurrentVolume } from 'features/audio/audioSlice';

interface VolumeControlProps {
  cssClass?: string;
}

export const VolumeControl: FunctionComponent<VolumeControlProps> = (props) => {
  const { setVolume, volume } = useAudio();
  const { isNativeAppWebview } = useContext(UserAgentContext);
  const currentVolume = useSelector(
    (state: RootState) => state.audio.currentVolume
  );
  const dispatch = useDispatch();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setCurrentVolume(volume));
    setVolume(Number(event.target.value));
  };

  const onClick = () => {
    dispatch(setCurrentVolume(volume));
    setVolume(volume === 0 ? currentVolume : 0);
  };

  if (isNativeAppWebview) {
    return null;
  }

  return (
    <div className={styles['volume']}>
      <button
        aria-controls="volume__control"
        onClick={onClick}
        className={classNames(
          styles['volume__button'],
          styles[props.cssClass ? props.cssClass : ''],
          { [styles['volume__button--mute']]: volume <= 0 },
          {
            [styles['volume__button--low']]: volume > 0 && volume < 0.3
          },
          {
            [styles['volume__button--mid']]: volume >= 0.3 && volume < 0.75
          },
          {
            [styles['volume__button--high']]: volume >= 0.75 && volume <= 1
          }
        )}
      >
        <Icon
          hidden={true}
          icon={'volume'}
          label={'Change volume'}
          height={20}
          width={20}
        />
        <span className="visually-hidden">Change volume</span>
      </button>
      <div id="volume__control" className={styles['volume__control']}>
        <input
          className={styles['volume__range']}
          id="volume"
          type="range"
          onChange={onChange}
          value={volume}
          aria-valuemin={0}
          aria-valuemax={1}
          aria-valuetext={volume.toString()}
          aria-valuenow={volume}
          min={0}
          max={1}
          step={0.01}
          style={{
            background: `
              linear-gradient(
              to right,
              var(--color__white) 0%,
              var(--color__white) ${volume * 100}%,
              rgb(var(--color__black-rgb), .3) ${volume * 100}%,
              rgb(var(--color__black-rgb), .3) 100%
            )
          `
          }}
        />
        <label className="visually-hidden" htmlFor="volume">
          Volume
        </label>
      </div>
    </div>
  );
};
