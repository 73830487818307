import React, { FunctionComponent } from 'react';
import { Icon } from '../Icons';

interface LoaderProps {
  fillComponent?: boolean;
  isWhite?: boolean;
  hideOnNative?: boolean;
  size?: number;
}

export const Loader: FunctionComponent<LoaderProps> = (props) => {
  if (props.hideOnNative) {
    return null;
  }

  const iconSize = props.size || 24;

  return (
    <div
      className={`tw-animate-spinLoader tw-inline-flex tw-items-center tw-justify-center ${
        props.fillComponent
          ? 'tw-absolute tw-top-1/2 tw-left-1/2 tw--translate-x-1/2 tw--translate-y-1/2 tw-z-[20]'
          : ''
      } ${
        props.isWhite
          ? 'tw-text-white [.light-contrast_&]:tw-text-black'
          : 'tw-text-black'
      } tw-w-[24px] tw-h-[24px]`}
    >
      <Icon
        icon={'loader'}
        hidden={false}
        label={'Loading...'}
        height={iconSize}
        width={iconSize}
      />
    </div>
  );
};
