import React, { FunctionComponent, useCallback, useContext } from 'react';
import { ReactComponent as EventBackground } from 'images/event-background.svg';
import { ReactComponent as RecordingBackground } from 'images/recording-background.svg';
import { ReactComponent as PrivacyBackground } from 'images/privacy-background.svg';
import { ThemeContext } from 'features/theme/theme';
import { useChatVisibleOnListenerMobile } from 'features/chat/chatHooks';

interface FullImageProps {
  artworkUrl: string | null;
  artworkMode?: string | undefined;
  updatedTimestamp?: string;
  type: 'event' | 'recording' | 'private';
  hideOverlay?: boolean;
  color?: string;
}

export const FullImage: FunctionComponent<FullImageProps> = ({
  artworkUrl,
  artworkMode,
  updatedTimestamp,
  type,
  hideOverlay,
  color
}) => {
  const { darkMode } = useContext(ThemeContext);

  const chatVisibleOnListenerMobile = useChatVisibleOnListenerMobile();

  const renderFallbackArtwork = useCallback(() => {
    switch (type) {
      case 'event':
        return <EventBackground />;
      case 'recording':
        return <RecordingBackground />;
      case 'private':
        return <PrivacyBackground />;
      default:
        return <EventBackground />;
    }
  }, [type]);

  const imageHeight = chatVisibleOnListenerMobile
    ? 'tw-h-[calc(50%+50px)]'
    : 'tw-h-full';

  if (!artworkUrl) {
    return renderFallbackArtwork();
  }

  return (
    <>
      <img
        src={`${artworkUrl}?ts=${updatedTimestamp}`}
        alt=""
        loading="lazy"
        className={`tw-absolute tw-object-cover tw-top-0 tw-left-0 tw-right-0 ${imageHeight} tw-w-full ${
          darkMode ? 'tw-filter tw-brightness-50' : ''
        }`}
      />
      {(artworkMode !== 'theme' || !hideOverlay) && (
        <div
          className={`tw-absolute tw-left-0 tw-top-0 tw-right-0 tw-bottom-0 tw-opacity-60 tw-mix-blend-multiply ${
            !color && 'tw-bg-theme-color'
          }`}
          style={color ? { backgroundColor: color } : {}}
        ></div>
      )}
      {!hideOverlay && (
        <div className="tw-absolute tw-left-0 tw-top-0 tw-right-0 tw-bottom-0 tw-bg-black/20"></div>
      )}
    </>
  );
};
