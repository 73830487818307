import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'rootReducer';
import { Pill } from 'components/Pill';
import { EventDate } from 'features/events/EventDate';
import { RecordingDate } from 'features/recordings/components/RecordingDate';
import { useCurrentEvent } from 'features/events/eventHooks';
import { useCurrentRecording } from 'features/recordings/recordingHooks';
import { FullImage } from 'components/Images/FullImage';
import { CardLink } from './CardLink';
import { SquareImage } from 'components/Images/SquareImage';
import { IconButton } from 'components/Buttons/IconButton';
import { formatTime } from 'utilities/formatters';
import { LiveNow } from 'components/LiveNow';
import tinycolor from 'tinycolor2';
import { useNavigate } from 'react-router-dom';
import { Media } from 'api/v3/types/media';
import { CardStatuses } from './CardStatuses';

interface CardProps {
  id: string;
  title: string;
  url?: string | null;
  artwork: Media;
  artworkKey?: string | null;
  artworkMode?: string | undefined;
  isPrivate?: boolean;
  isActive?: boolean;
  isFeatured?: boolean;
  type: 'event' | 'recording';
  updatedTimestamp?: string;
  duration?: number | null;
  color: string;
}

export const Card: FunctionComponent<CardProps> = (props) => {
  const playing = useSelector((state: RootState) => state.audio.playing);
  const eventIsLive = props.isActive;
  const event = useCurrentEvent();
  const recording = useCurrentRecording();
  const [colorContrastClass, setColorContrastClass] = useState<string>('');
  const navigate = useNavigate();

  const hasImage = props.artworkMode !== 'theme';

  const featuredText =
    props.type === 'event' ? 'Next live event' : 'Latest recording';

  const isPlaying = () => {
    if (
      playing &&
      ((event && event.id === props.id && eventIsLive) ||
        (recording && recording.id === props.id))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isPlayable = props.type === 'recording' || eventIsLive;
  const friendlyDuration = formatTime(props.duration ? props.duration : 0);

  const cardContentFeatured = (
    <div
      className={`tw-relative tw-flex tw-py-16 sm:tw-py-24 md:tw-py-32 lg:tw-py-40 tw-items-center tw-justify-center tw-mb-8 ${
        hasImage ? 'has-image' : ''
      }`}
    >
      <FullImage
        artworkUrl={props.artwork.image_blur.large || null}
        artworkMode={props.artworkMode}
        updatedTimestamp={props.updatedTimestamp}
        type={props.type}
        color={props.color}
      />
      <div className="tw-grid tw-grid-cols-12 tw-gap-8 lg:tw-gap-16 tw-max-w-screen-xl tw-w-full tw-mx-auto tw-relative [.has-image_&]:!tw-text-white">
        <div className="tw-col-span-12 md:tw-col-span-6 tw-text-center md:tw-text-right">
          <div className="tw-mb-4 md:tw-mb-0 tw-max-w-[180px] md:tw-max-w-sm xl:tw-max-w-xl tw-w-full tw-inline-block">
            <SquareImage
              artworkUrl={props.artwork.image.large}
              cssClasses={'tw-shadow-glow'}
            />
          </div>
        </div>
        <div className="tw-col-span-12 md:tw-col-span-6 tw-text-center md:tw-text-left tw-flex tw-items-center">
          <div className="tw-w-full [&>*]:!tw-text-white">
            {!eventIsLive && (
              <Pill
                label={featuredText}
                type={'default'}
                icon={props.type === 'event' ? 'calendar' : 'play'}
              />
            )}
            <LiveNow isLive={eventIsLive} />
            <h2 className="tw-mt-4 tw-mb-2">{props.title}</h2>
            {props.type === 'event' ? (
              <EventDate eventId={props.id} cssClass="tw-block tw-mb-4" />
            ) : (
              <RecordingDate
                recordingId={props.id}
                cssClass="tw-block tw-mb-4"
              />
            )}
            {isPlayable && (
              <div
                className={`tw-pointer-events-none tw-inline-flex tw-items-center tw-gap-4 tw-rounded-full tw-font-bold tw-text-white hover:tw-text-white/50 tw-py-2 tw-pl-2 tw-pr-8 ${
                  props.artworkKey
                    ? 'tw-bg-theme-color [.light-contrast_&]:tw-text-black'
                    : 'tw-bg-black/20 hover:tw-bg-black/50 tw-backdrop-blur-sm'
                }`}
                style={props.artworkKey ? { backgroundColor: props.color } : {}}
              >
                <IconButton
                  label={'Play audio'}
                  isDisabled={true}
                  icon={'play'}
                  isTransparent={true}
                  isPlaying={isPlaying()}
                  onClick={() => navigate(`${props.url}`)}
                />
                <>
                  {isPlaying() ? (
                    'View'
                  ) : (
                    <>{props.type === 'event' ? 'Play' : friendlyDuration}</>
                  )}
                </>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const cardContent = (
    <div className="tw-rounded-3xl [.ios_&]:tw-rounded-xl tw-overflow-hidden tw-relative">
      <SquareImage
        artworkUrl={props.artwork.image.large}
        updatedTimestamp={props.updatedTimestamp}
        squareCorners={true}
      />
      <div className="tw-flex tw-flex-col tw-p-4 [.ios_&]:tw-p-2 tw-absolute tw-bottom-0 tw-left-0 tw-w-full tw-z-20 tw-rounded-b-2xl">
        <div className="tw-flex-1 tw-text-xl [.ios_&]:tw-text-sm tw-mb-4 [.ios_&]:tw-mb-2">
          <h2 className="tw-mb-0 tw-text-2xl [.ios_&]:tw-text-base tw-line-clamp-2">
            {props.title}
          </h2>
          {props.type === 'event' ? (
            <EventDate eventId={props.id} />
          ) : (
            <RecordingDate recordingId={props.id} />
          )}
        </div>
        <CardStatuses
          type={props.type}
          isLive={eventIsLive}
          isPrivate={props.isPrivate}
          duration={friendlyDuration}
        />
      </div>
      <div
        className="tw-pointer-events-none tw-absolute tw-left-0 tw-top-0 tw-right-0 tw-bottom-0 tw-z-10 tw-rounded-2xl"
        style={{
          background: `linear-gradient(5deg, ${props.color}, ${props.color} 25%, ${props.color}CC 35%, ${props.color}80 40%, ${props.color}00 50%`
        }}
      ></div>
    </div>
  );

  useEffect(
    function setCardColorContrast() {
      const color = tinycolor(props.color);
      const colorIsLight = color.getLuminance() > 0.5;
      setColorContrastClass(colorIsLight ? 'light-contrast' : 'dark-contrast');
    },
    [props.color, setColorContrastClass]
  );

  return (
    <article className={`tw-group/card ${colorContrastClass}`}>
      {props.url ? (
        <CardLink
          id={props.id}
          url={props.url}
          type={props.type}
          autoPlay={isPlayable}
          eventIsLive={eventIsLive}
        >
          {props.isFeatured ? cardContentFeatured : cardContent}
        </CardLink>
      ) : props.isFeatured ? (
        cardContentFeatured
      ) : (
        cardContent
      )}
    </article>
  );
};
