import { IconButton } from 'components/Buttons/IconButton';
import { useIsFullScreenView } from 'features/theme/themeHooks';
import { UserAgentContext } from 'features/user-agent/userAgent';
import React, { FunctionComponent, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

export const CreatorSignUpButton: FunctionComponent = () => {
  const { isEmbed } = useContext(UserAgentContext);
  const shouldRender =
    useMediaQuery({ query: '(min-width: 900px)' }) && !isEmbed;
  const isFullScreenView = useIsFullScreenView();

  if (shouldRender) {
    return (
      <IconButton
        showLabel={true}
        icon={'mixlr'}
        label={'Go Live'}
        href={`${process.env.REACT_APP_CREATOR_ENDPOINT}`}
        external={true}
        isTransparent={isFullScreenView}
        isLight={!isFullScreenView}
        target="_blank"
        rel="noopener noreferrer"
      />
    );
  }

  return null;
};
