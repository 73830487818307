import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiV3ValidateAccesscode } from 'api/v3/accesscode';
import {
  apiV3GetChannel,
  mapApiV3ChannelViewCurrentBroadcast,
  mapApiV3PrivateChannelToAccessCodeDetails,
  mapApiV3ChannelViewToState,
  mapApiV3PaidChannelToPaidChannelDetails
} from 'api/v3/channel_view';
import { V3ChannelView } from 'api/v3/types/channel_view';
import { V3ChannelViewPrivate } from 'api/v3/types/channel_view_private';
import { addBroadcast } from 'features/broadcast/broadcastsSlice';
import { AppDispatch, AppThunk } from 'store';
import {
  addEvents,
  addPrivateEvents,
  mapV3ChannelViewToEvents,
  mapV3ChannelViewToPrivateEvents
} from 'features/events/eventsSlice';
import { airbrakeNetworkError } from 'utilities/airbrake';
import { isNativeAppWebview } from 'lib/useragents';
import { Media } from 'api/v3/types/media';

export interface ChannelState {
  channelDetails: ChannelDetails | null;
  isLoaded: boolean;
  embedPlayerLoaded: boolean;
  requiresAccesscode: boolean;
  accessCodeDetails: AccesscodeDetails | null;
  requiresChannelSubscription: boolean;
  miniPlayerVisible: boolean;
  channelUnavailable: boolean;
  paidChannelDetails: PaidChannelDetails | null;
}

export interface ChannelLink {
  name: string;
  url: string;
}

export interface AccesscodeDetails {
  id: string;
  link: string;
  linkType: string;
  copy: string;
  failed: boolean;
  artworkKey: string | null;
  logoKey: string | null;
  username: string;
  themeColor: string;
  aboutMe: string;
  channelLogo: Media;
  channelArtwork: Media;
}
export interface PaidChannelDetails {
  id: number | null;
  link: string;
  linkType: string;
  copy: string;
  failed: boolean;
  artworkKey: string | null;
  logoKey: string | null;
  username: string;
  themeColor: string;
  channelLogo: Media;
  channelArtwork: Media;
}

export interface ChannelDetails {
  channelId: string | null;
  ownerId: string;
  username: string;
  links: ChannelLink[];
  listeners: number;
  followers: number;
  aboutMe: string;
  themeColor: string;
  artworkKey: string | null;
  logoKey: string | null;
  featureFlagEnabled: boolean;
  legacyLivepageUrl: string;
  isPrivate: boolean;
  liveEmbedPlayerEnabled: boolean;
  recordingEmbedPlayerEnabled: boolean;
  gifsInChatEnabled: boolean;
  embedOnly: boolean;
  removeEmbedBranding: boolean;
  removeEmbedShareButton: boolean;
  channelLogo: Media;
  channelArtwork: Media;
}

export const initialChannelState: ChannelState = {
  channelDetails: null,
  isLoaded: false,
  embedPlayerLoaded: false,
  requiresAccesscode: false,
  accessCodeDetails: null,
  requiresChannelSubscription: false,
  paidChannelDetails: null,
  miniPlayerVisible: false,
  channelUnavailable: false
};

const channelSlice = createSlice({
  name: 'channel',
  initialState: initialChannelState,
  reducers: {
    getChannelViewSuccess(state, action: PayloadAction<ChannelDetails>) {
      state.channelDetails = action.payload;
      state.isLoaded = true;
    },
    getChannelFailed(_state, action: PayloadAction<string>) {
      console.log(action.payload);
    },
    setAccesscodeDetails(
      state,
      action: PayloadAction<AccesscodeDetails | null>
    ) {
      state.requiresAccesscode = action.payload != null;
      state.accessCodeDetails = action.payload;
    },
    accesscodeValidateSuccess(state) {
      state.requiresAccesscode = false;
    },
    accesscodeValidateFailure(state) {
      if (state.accessCodeDetails) {
        state.accessCodeDetails.failed = true;
      }
    },
    setRequiresChannelSubscription(
      state,
      action: PayloadAction<PaidChannelDetails | null>
    ) {
      state.requiresChannelSubscription = true;
      state.paidChannelDetails = action.payload;
    },
    disableRequiresChannelSubscription(state) {
      state.requiresChannelSubscription = false;
    },
    setMiniPlayerVisible(state, action) {
      state.miniPlayerVisible = action.payload;
    },
    setChannelUnavailable(
      state,
      { payload: channelUnavailable }: PayloadAction<boolean>
    ) {
      state.channelUnavailable = channelUnavailable;
      state.isLoaded = true;
    },
    setEmbedPlayerLoaded(state, action) {
      state.embedPlayerLoaded = action.payload;
    }
  }
});

export const {
  getChannelViewSuccess,
  getChannelFailed,
  setAccesscodeDetails,
  accesscodeValidateSuccess,
  accesscodeValidateFailure,
  setRequiresChannelSubscription,
  disableRequiresChannelSubscription,
  setMiniPlayerVisible,
  setChannelUnavailable,
  setEmbedPlayerLoaded
} = channelSlice.actions;

export default channelSlice.reducer;

export const fetchChannelView =
  (username: string, retries = 2): AppThunk =>
  async (dispatch) => {
    let response;
    try {
      response = await apiV3GetChannel(username);
    } catch (error_response) {
      if (error_response.status === 402) {
        const json_response = await error_response.json();

        handlePaidChannel(json_response as V3ChannelViewPrivate, dispatch);
      } else if (error_response.status === 404) {
        handleChannel404(username);
      } else if (error_response.status === 423) {
        const json_response = await error_response.json();

        handleChannelLocked(json_response.data.attributes.username);
      } else if (error_response.status === 451) {
        dispatch(setChannelUnavailable(true));
      } else {
        airbrakeNetworkError(error_response);

        dispatch(getChannelFailed(error_response?.body?.toString()));
      }
    }
    if (!response) {
      return;
    }

    if (retries > 0) {
      setTimeout(() => {
        dispatch(fetchChannelView(username, retries - 1));
      }, 10000);
    }

    dispatch(disableRequiresChannelSubscription());
    if (response.data.type === 'channel_view_private') {
      handlePrivateChannel(response as V3ChannelViewPrivate, dispatch);
    } else {
      handleChannel(response as V3ChannelView, dispatch);
    }
  };

const handlePaidChannel = (
  channel: V3ChannelViewPrivate,
  dispatch: AppDispatch
): void => {
  dispatch(
    setRequiresChannelSubscription(
      mapApiV3PaidChannelToPaidChannelDetails(channel)
    )
  );
};

const handlePrivateChannel = (
  channel: V3ChannelViewPrivate,
  dispatch: AppDispatch
): void => {
  dispatch(
    setAccesscodeDetails(mapApiV3PrivateChannelToAccessCodeDetails(channel))
  );
};

const handleChannel = (channel: V3ChannelView, dispatch: AppDispatch): void => {
  const mappedChannel = mapApiV3ChannelViewToState(channel as V3ChannelView);

  if (!mappedChannel.featureFlagEnabled && !isNativeAppWebview()) {
    handleChannel404(mappedChannel.username);
    return;
  }

  const currentBroadcast = mapApiV3ChannelViewCurrentBroadcast(channel);

  dispatch(addEvents(mapV3ChannelViewToEvents(channel)));
  dispatch(addPrivateEvents(mapV3ChannelViewToPrivateEvents(channel)));
  dispatch(getChannelViewSuccess(mappedChannel));

  if (currentBroadcast) {
    dispatch(addBroadcast(currentBroadcast));
  }
};

const handleChannel404 = (username: string) => {
  window.location.assign(
    `${process.env.REACT_APP_WEBSITE_ENDPOINT}/${username}`
  );
};

const handleChannelLocked = (username: string) => {
  window.location.assign(
    `${process.env.REACT_APP_WEBSITE_ENDPOINT}/${username}`
  );
};

export const fetchValidateAccesscode =
  (
    channel: string,
    channelId: string,
    accesscode: string,
    postMessage?: any
  ): AppThunk =>
  async (dispatch) => {
    const result = await apiV3ValidateAccesscode(channel, accesscode);

    if (result) {
      const cookieString = `private_${channelId} = ${accesscode};${process.env.REACT_APP_COOKIE_SUFFIX}`;
      document.cookie = cookieString;
      dispatch(accesscodeValidateSuccess());
      dispatch(fetchChannelView(channel));
      if (postMessage) {
        postMessage({ accessCodeValidate: true });
      }
    } else {
      dispatch(accesscodeValidateFailure());
    }
  };
